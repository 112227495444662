import React, { Fragment, useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  SectionTitle,
  Splash,
  SeeMoreLink3,
  PlusIcon,
  ArrowDownIcon,
  ProjetLink,
  ProjetsWrapper,
  ArrowDownLink,
  ArrowUpIcon,
  ArrowUpLink,
 
  Text2Col,
  Grid2Col,
  Spacer,

} from '../components/Elements';
import * as modalTypes from '../types/modalTypes';
import ModalContext from '../store/modalContext';
import SEO from '../components/SEO';


const indexQuery = graphql`
  {
    datoCmsHomepage {
      titreDeLaSectionBienvenue
      texteDeLaSectionBienvenue
      titreDeLaSectionProjets
      titreDeLaSectionRessources
      texteDeLaSectionRessources
      projetsHomepage {
          id
          titre
          typeProjet  {
            typeProjet
          }
          annE
          surface
          slug
          imagePrincipale {
            fluid(maxHeight: 300, forceBlurhash: false, imgixParams: {  fit: "crop", w: "575", h: "300" , fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
            }
          }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function IndexPage() {
  const data = useStaticQuery(indexQuery);
  const { openModal } = useContext(ModalContext);
  const {
    titreDeLaSectionBienvenue,
    texteDeLaSectionBienvenue,
    titreDeLaSectionProjets,
    titreDeLaSectionRessources,
    texteDeLaSectionRessources,
    seoMetaTags,
    projetsHomepage,
  } = data.datoCmsHomepage;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper id="top-content">
        <PageInner>

          <PageTitle>{titreDeLaSectionBienvenue}</PageTitle>          
          <Text2Col dangerouslySetInnerHTML={{ __html: texteDeLaSectionBienvenue }}/>
          <div style={{"textAlign":"center"}}>
            <SeeMoreLink3 to="/bla-bla" title="Vous souhaitez en savoir plus sur notre vision, notre équipe ? C'est par ici">
            <PlusIcon title="Vous souhaitez en savoir plus sur notre vision, notre équipe ? C'est par ici" />
            <span>En savoir plus</span> 
          </SeeMoreLink3>
          </div>
          
         
          
          <SectionTitle>{titreDeLaSectionProjets}</SectionTitle> 
          <ProjetsWrapper type="homepage">
                { _map(projetsHomepage, (projet) => (
                  <ProjetLink key={projet.id}>
                     <Link to={`/projets/${projet.slug}/`}>
                      <Img fluid={projet.imagePrincipale.fluid} alt={projet.titre}/>
                        <h3 className="title">{projet.titre}</h3>
                        <span className="description">
                        {projet.typeProjet.typeProjet} / {projet.surface} m² / {projet.annE} </span>
                    </Link>
                  </ProjetLink>
                ))}
          </ProjetsWrapper>
          <div style={{"textAlign":"center"}}>
            <SeeMoreLink3 to="/projets" title="Voir tous les projets">
             <PlusIcon title="Découvrez tous nos projets" />
             <span>Voir tous les projets</span>
            </SeeMoreLink3>
          </div>

          <Spacer/>
          
      {/* <SectionTitle>{titreDeLaSectionRessources}</SectionTitle> 
          <Text2Col  dangerouslySetInnerHTML={{ __html: texteDeLaSectionRessources}}/>
          <div style={{"textAlign":"center"}}>
            <SeeMoreLink3 to="/ressources" title="Consulter les ressources">
             <PlusIcon title="Consulter les ressources" />
             <span>Consulter les ressources</span> 
            </SeeMoreLink3>
          </div>
          
          <Spacer/>
        */}   
        </PageInner>
      </PageWrapper>
     
    </Fragment>
  );
}
